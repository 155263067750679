import { css, type Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { addAlpha, colors } from 'fitify-ui/src/theme/colors'

import { BREAKPOINTS } from '../../utils/styled'
import { Stack } from '../Stack'

export const StyledThresholdBackground = styled.div<{
  height?: number
  isActive: boolean
}>`
  ${(props) => {
    if (props.isActive) {
      const defaultStyle = css`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: ${props.height || 118}px;
        opacity: 90%;

        @media ${BREAKPOINTS.MD.lte} {
          width: 100%;
        }
      `
      switch (true) {
        case props.theme.digitalV2:
          return css`
            ${defaultStyle}
            max-width: var(--w-content);
            background: linear-gradient(
              180deg,
              rgba(245, 245, 245, 0) 0%,
              var(--c-blue-base) 35%
            );
          `
        default:
          return null
      }
    }
    return null
  }}
`

export const StyledThresholdWrapper = styled(Stack)`
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: var(--s-continue-button);
  z-index: var(--z-index-threshold-button);
  pointer-events: none;

  @media (max-height: 400px) {
    margin-top: 2rem;
    position: relative;
  }

  button {
    pointer-events: auto;
  }
`

const getSurfaceColor = (theme: Theme) => {
  switch (true) {
    case theme.hcV1:
      return colors.Gray100
    case theme.digitalV3:
      return colors.Blue0
    default:
      return colors.White
  }
}

export const StyledThresholdBackgroundV2 = styled.div`
  ${(props) => {
    const themeSurface = getSurfaceColor(props.theme)
    const alphaColor = addAlpha(themeSurface, 0.9)
    const transparent = addAlpha('#ffffff', 0)

    return css`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: calc(100% + var(--s-continue-button));
      z-index: -1;

      background: linear-gradient(180deg, ${transparent} 0%, ${alphaColor} 35%);

      @media ${BREAKPOINTS.MD.lte} {
        width: 100%;
      }
    `
  }}
`

export const StyledCoachContainer = styled(Stack)`
  height: 100%;
`
